import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import { ContentSection } from "../components/ContentSection"
import ActionButton from "../components/ActionButton"
import ContactFormContainer from "./ContactFormContainer"

const Container = styled("div")`
  border-radius: 3px;
  flex: 0 1 900px;
  max-width: 900px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: #494c47;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: ${props =>
      props.imageUrl ? `url(${props.imageUrl})` : "none"};
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    border-radius: 3px;
    opacity: 0.3;
  }

  .content {
    color: white;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    padding: 1rem;

    .description {
      text-transform: uppercase;
    }

    .header {
      font-family: "montserrat";
      font-weight: 700;
      font-size: 2rem;

      @media screen and (max-width: 480px) {
        font-size: 1.25rem;
      }
    }
  }
`

const LargeCallToAction = ({ doc }) => {
  const [showContactForm, setShowContactForm] = useState(false)

  const handleClose = useCallback(() => setShowContactForm(false), [
    setShowContactForm,
  ])
  return (
    <>
      {doc.node.body &&
        doc.node.body
          .filter(section => section.type === "banner_with_caption")
          .map(section => (
            <ContentSection>
              <div
                className="inner-container"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Container imageUrl={section.primary.image_banner.url}>
                  <div className="content">
                    <p className="description">
                      {RichText.asText(section.primary.description)}
                    </p>
                    <p className="header">
                      {RichText.asText(section.primary.title_of_banner)}
                    </p>
                    <ActionButton onClick={() => setShowContactForm(true)}>
                      {RichText.asText(section.primary.button_label)}
                    </ActionButton>
                  </div>
                </Container>
              </div>
            </ContentSection>
          ))}
      {showContactForm && <ContactFormContainer onClose={handleClose} />}
    </>
  )
}

export default LargeCallToAction
