import React, { useContext } from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import VisibilitySensor from "react-visibility-sensor"
import { ActiveScrollContext } from "../hooks/ActiveScrollContext"
import LargeCallToAction from "./LargeCallToAction"

const EngagementModel = styled("div")`
  .cards-container {
    display: -ms-grid; /* IE11 sucks */
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr; /* IE11 sucks */
    -ms-grid-rows: 170px 170px 170px 170px; /* IE11 sucks */
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 170px);
    grid-gap: 1rem;

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      -ms-grid-columns: 1fr; /* IE11 sucks */
      -ms-grid-rows: auto; /* IE11 sucks */
    }
  }

  .card {
    background-color: #fff;
    padding: 1rem;
    box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.16);
    border-radius: 3px;

    /* IE11 hack */
    @media all and (-ms-high-contrast: none) {
      margin-bottom: 1rem;
    }

    .card-header {
      font-family: "montserrat";
      font-size: 1rem;
      font-weight: 700;
    }

    p {
      font-size: 0.9rem;
    }
  }

  .card-1 {
    grid-column: 1;
    grid-row: 2 / span 2;

    @media screen and (max-width: 480px) {
      grid-column: 1;
      grid-row: 1;
    }
  }

  .card-2 {
    grid-column: 2;
    grid-row: 1 / span 2;

    @media screen and (max-width: 480px) {
      grid-column: 1;
      grid-row: 2;
    }
  }

  .card-3 {
    grid-column: 2;
    grid-row: 3 / span 2;

    @media screen and (max-width: 480px) {
      grid-column: 1;
      grid-row: 3;
    }
  }

  .card-4 {
    grid-column: 3;
    grid-row: 2 / span 2;

    @media screen and (max-width: 480px) {
      grid-column: 1;
      grid-row: 4;
    }
  }

  .card-5 {
    grid-column: 4;
    grid-row: 1 / span 2;

    @media screen and (max-width: 480px) {
      grid-column: 1;
      grid-row: 5;
    }
  }
`

const CrgToolbox = styled("div")`
  margin-bottom: 100px;

  .cards-container {
    display: -ms-grid; /* IE11 sucks */
    display: grid;
    -ms-grid-columns: 1fr 1fr; /* IE11 sucks */
    -ms-grid-rows: auto auto; /* IE11 sucks */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 2rem;
  }

  .card {
    background-color: #fff;
    padding: 1rem;
    box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.16);
    border-radius: 3px;

    /* IE11 hack */
    @media all and (-ms-high-contrast: none) {
      margin: 1rem;
    }

    h4 {
      font-family: "montserrat";
      font-size: 1rem;
      margin: 0.5rem 0;
    }

    p {
      font-size: 0.9rem;
    }
  }

  .card-1 {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }

  .card-2 {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }

  .card-3 {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
  }

  .card-4 {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
  }

  .card:nth-child(even) {
    transform: translateY(50px);
  }
`

const WhyCrg = styled("div")`
  margin-bottom: 2rem;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  .main-content {
    flex: 1.5;
    margin-right: 4rem;

    h4 {
      font-size: 1rem;
      font-family: "montserrat";
    }

    p {
      font-size: 0.9rem;
    }

    @media screen and (max-width: 480px) {
      flex: 1;
      margin-right: 0;
    }
  }

  .stats {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;
    position: relative;

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      margin-top: 3rem;
    }
  }

  .stat-item {
    .stat-value {
      font-family: "montserrat";
      font-size: 3rem;
    }

    .stat-adornment {
      font-family: "montserrat";
      font-size: 3rem;
      color: #0099cb;
    }

    h5 {
      font-size: 0.9rem;
      font-family: "montserrat";
      margin: 0.5rem 0;
    }

    p {
      font-size: 0.9rem;
    }
  }

  .stat-item-0 {
    grid-column: 1;
    grid-row: 1;
  }

  .stat-item-1 {
    grid-column: 2;
    grid-row: 1;

    @media screen and (max-width: 480px) {
      grid-column: 1;
      grid-row: 2;
    }
  }

  .stat-item-2 {
    grid-column: 1;
    grid-row: 2;

    @media screen and (max-width: 480px) {
      grid-column: 1;
      grid-row: 3;
    }
  }

  .stat-item-3 {
    grid-column: 2;
    grid-row: 2;

    @media screen and (max-width: 480px) {
      grid-column: 1;
      grid-row: 4;
    }
  }
`

const CapabilitiesContentContainer = ({ doc }) => {
  // for scrolling
  const [, setActiveItems] = useContext(ActiveScrollContext)
  function onChange(isVisible, id) {
    setActiveItems({ id, isVisible })
  }
  return (
    <>
      {doc.node.body.map(b => {
        return b.type === "engagement_model" ? (
          <VisibilitySensor
            onChange={isVisible =>
              onChange(
                isVisible,
                b.primary.section_title[0].text.replace(" ", "_")
              )
            }
            key={b.primary.section_title[0].text.replace(" ", "_")}
            partialVisibility="top"
          >
            <EngagementModel>
              <div
                className="anchor"
                id={b.primary.section_title[0].text.replace(" ", "_")}
              />
              <p class="intro-text">{b.primary.intro_text[0].text}</p>
              {b.fields && (
                <div className="cards-container">
                  {b.fields.map((f, i) => (
                    <div className={`card card-${i + 1}`}>
                      <img src={f.model_card_icon.url} alt="card icon" />
                      <p className="card-header">
                        {f.model_card_title[0].text}
                      </p>
                      {RichText.render(f.model_card_description)}
                    </div>
                  ))}
                </div>
              )}
            </EngagementModel>
          </VisibilitySensor>
        ) : b.type === "crg_toolbox" ? (
          <VisibilitySensor
            onChange={isVisible =>
              onChange(
                isVisible,
                b.primary.section_title[0].text.replace(" ", "_")
              )
            }
            key={b.primary.section_title[0].text.replace(" ", "_")}
            partialVisibility="top"
          >
            <CrgToolbox>
              <div
                className="anchor"
                id={b.primary.section_title[0].text.replace(" ", "_")}
              />
              <h3>{b.primary.section_title[0].text}</h3>
              <p class="intro-text">{b.primary.intro_text[0].text}</p>
              {b.fields && (
                <div className="cards-container">
                  {b.fields.map((f, i) => (
                    <div className={`card card-${i + 1}`}>
                      <h4>{f.card_title[0].text}</h4>
                      {RichText.render(f.card_description)}
                    </div>
                  ))}
                </div>
              )}
            </CrgToolbox>
          </VisibilitySensor>
        ) : b.type === "why_crg" ? (
          <VisibilitySensor
            onChange={isVisible =>
              onChange(
                isVisible,
                b.primary.section_title[0].text.replace(" ", "_")
              )
            }
            key={b.primary.section_title[0].text.replace(" ", "_")}
            partialVisibility="top"
          >
            <WhyCrg>
              <div
                className="anchor"
                id={b.primary.section_title[0].text.replace(" ", "_")}
              />
              <h3>{b.primary.section_title[0].text}</h3>
              <div className="content">
                <div className="main-content">
                  {RichText.render(b.primary.main_section_content)}
                </div>
                <div className="stats">
                  <div className="crg-logo-bg" />
                  {b.fields &&
                    b.fields.map((f, i) => (
                      <div className={`stat-item stat-item-${i}`}>
                        <div className="stat-container">
                          <span className="stat-value">
                            {f.statistic_number[0].text}
                          </span>
                          <span className="stat-adornment">
                            {f.statistic_adornment[0].text}
                          </span>
                        </div>
                        <h5 className="stat-title">
                          {f.statistic_title[0].text}
                        </h5>
                        <p className="stat-description">
                          {f.statistic_description[0].text}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </WhyCrg>
          </VisibilitySensor>
        ) : b.type === "banner_with_caption" ? (
          <LargeCallToAction doc={doc} />
        ) : null
      })}
    </>
  )
}

export default CapabilitiesContentContainer
