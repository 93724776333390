import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { ContentSection } from "../components/ContentSection"
import { RichText } from "prismic-reactjs"
import SideNav from "../components/SideNav"
import TwoColContainer from "../components/TwoColContainer"
import TopSubNav from "../components/TopSubNav"
import CapabilitiesContentContainer from "../components/CapabilitiesContentContainer"
import styled from "styled-components"
import CallToActionContact from "../components/CallToActionContact"

export const query = graphql`
  {
    prismic {
      allCapabilitiess {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_CapabilitiesBodyBanner_with_caption {
                type
                primary {
                  button_label
                  description
                  image_banner
                  title_of_banner
                }
              }
              ... on PRISMIC_CapabilitiesBodyEngagement_model {
                type
                primary {
                  intro_text
                  section_title
                }
                fields {
                  model_card_description
                  model_card_icon
                  model_card_title
                }
              }
              ... on PRISMIC_CapabilitiesBodyCrg_toolbox {
                type
                fields {
                  card_description
                  card_title
                }
                primary {
                  section_title
                  intro_text
                }
              }
              ... on PRISMIC_CapabilitiesBodyWhy_crg {
                type
                fields {
                  statistic_adornment
                  statistic_description
                  statistic_number
                  statistic_title
                }
                primary {
                  intro_text
                  main_section_content
                  section_title
                }
              }
            }
          }
        }
      }
    }
  }
`

const links = [
  {
    key: "about",
    stub: "/about",
    displayName: "About",
  },
  {
    key: "team",
    stub: "/team",
    displayName: "Team",
  },
  {
    key: "capabilities",
    stub: "/capabilities",
    displayName: "Capabilities",
  },
  {
    key: "culture",
    stub: "/culture",
    displayName: "Culture",
  },
  {
    key: "careers",
    stub: "/careers",
    displayName: "Careers",
  },
]

const StyledContentSection = styled(ContentSection)`
  background-image: url("https://images.prismic.io/crgwebsite/fa044ae8-6984-4ba8-811a-daad5be74a03_crg-logo-bg.svg?auto=compress,format");
  background-repeat: no-repeat;
  background-position: calc(100% + 200px) calc(100% + 150px);

  @media screen and (max-width: 480px) {
    background-image: none;
  }
`

const renderComponent = data => {
  const doc = data.prismic.allCapabilitiess.edges.slice(0, 1).pop()
  if (!doc) return null
  const topScrollLinks = doc.node.body
    .filter(b => b.type !== "banner_with_caption")
    .map(b => ({
      id: b.primary.section_title[0].text.replace(" ", "_"),
      label: b.primary.section_title[0].text,
      isVisible: false,
    }))

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={doc.node.page_title[0].text}
        topScrollLinks={topScrollLinks}
      />
      <StyledContentSection>
        <div className="inner-container">
          <TwoColContainer>
            <div className="left-col">
              <SideNav links={links} />
            </div>
            <div className="right-col">
              {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
              <CapabilitiesContentContainer doc={doc} />
            </div>
          </TwoColContainer>
        </div>
      </StyledContentSection>
      <CallToActionContact />
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderComponent, query)} />
    </>
  )
}
